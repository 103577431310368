import { AccountExtensao } from "./AccountExtensao";


export class AccountExtensaoRepository {
    async search(): Promise<AccountExtensao[]> {
        try {
            let records = await $.ajax({
                url: `account-extensao/search`,
                type: 'get',
                dataType: 'json'
            });

            return records;
        } catch (error) {
            return [];
        }
    }
    async save(record: AccountExtensao): Promise<AccountExtensao> {
        try {

            return await $.ajax({
                url: `account-extensao/save`,
                type: 'post',
                dataType: 'json',
                data: {
                    ...record
                }
            });
        } catch (error: any) {
            const text = error?.responseJSON?.error || error?.message || "Não foi possível enviar o formulário";
            throw new Error(text);
        }
    }
    async remove(extensao_id: string): Promise<void> {
        try {

            await $.ajax({
                url: `account-extensao/remove`,
                type: 'delete',
                dataType: 'json',
                data: {
                    extensao_id
                }
            });
        } catch (error: any) {
            const text = error?.responseJSON?.error || error?.message || "Não foi possível enviar o formulário";
            throw new Error(text);
        }
    }
}
