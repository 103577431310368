if (import.meta.hot) {
    import.meta.hot.accept()
}

import Swal from 'sweetalert2'
import '../scss/argon.scss'
import './modulos/default'
import '../less/main.less'

if (document.querySelector('.editor-quill')) {
    import('@components/editor')
}

if (document.querySelector('.file-manager')) {
    import('@components/file-manager')
}

if (document.querySelector('.mapa')) {
    import('@components/mapa')
}

if (document.querySelector('body[data-controller=imoveis]')) {
    import('./modulos/imoveis')
}
if (document.querySelector('body[data-controller=empreendimentos]')) {
    import('./modulos/empreendimentos')
}
if (document.querySelector('body[data-controller=unidades]')) {
    import('./modulos/unidades')
}

if (document.querySelector('body[data-controller=bairros]')) {
    import('./modulos/bairros')
}


if (document.querySelector('body[data-controller=zonas]')) {
    import('./modulos/zonas')
}

if (document.querySelector('body[data-controller=paginas]')) {
    import('./modulos/paginas')
}

if (document.querySelector('.image-selector')) {
    import('@components/image-selector')
}

if (document.querySelector('.create-account')) {
    import('./modulos/create-account')
}

if (document.querySelector('[name=formRecuperarSenha]')) {
    import('./modulos/recuperar-senha')
}
if (document.querySelector('[name=formRedefinirSenha]')) {
    import('./modulos/redefinir-senha')
}
if (document.querySelector('[name=formRegistro]')) {
    import('./modulos/registro')
}
if (document.querySelector('.credit-card')) {
    import('./modulos/credit-card')
}

if (document.querySelector('.modal-meu-plano')) {
}
import('./modulos/meu-plano')
import('./modulos/empresas')

import './modulos/modal-cpf-cnpj'

import './modulos/extensoes'


$(document).on('submit', '[name=formAlterarPlano]', function () {
    const $btn = $(this).find('[type=submit]')
    $btn.html('Salvando...');
    const execute = async () => {
        try {
            const id = $(this).find('[name=id]').val() as string
            const plano_id = $(this).find('[name=plano_id]').val() as string

            const data = new FormData()
            data.set('id', id)
            data.set('plano_id', plano_id)
            
            const resp = await fetch(`minha-conta/alterar-plano`, {
                method: "POST",
                body: data
            }).then(async (resp) => {
                const data = await resp.json()
                if (!resp.ok) {
                    throw new Error(data.error)
                }
                return data
            })

            await Swal.fire({
                icon: "success",
                title: "Plano alterado com sucesso"
            })
            location.reload()
        } catch (error: any) {
            await Swal.fire({
                icon: 'error',
                title: "Oops",
                //text: "Não foi possível alterar o plano"
                text: error.message
            })
        }
        $btn.html('Salvar');
    }
    execute()
    return false
})


if (document.querySelector('.sortable')) {
    const folder = $('.sortable').data('folder')
    $('.sortable').sortable({
        axis: 'y',
        containment: 'parent',

        async update(event, ui) {

            let $item = ui.item
            let id = $item.data('id')
            let ordenamento = $item.data('ordenamento')
            let item = {
                id,
                ordenamento
            }

            let prev = 0
            let next = Date.now() * 1000
            let $prev_item = $item.prev()
            let $next_item = $item.next()
            let novo_ordenamento = ordenamento
            if ($prev_item) {
                prev = $prev_item.data('ordenamento')
            }
            if ($next_item) {
                next = $next_item.data('ordenamento')
            }
            novo_ordenamento = Math.round((prev + next) / 2)

            let resp = await $.ajax({
                url: `${folder}/set-ordenamento`,
                method: 'post',
                dataType: 'json',
                data: {
                    id: item.id,
                    ordenamento: novo_ordenamento
                }
            })
           
            //location.reload()
        }
    })
}

// funções

$(function () {
    $('body').on('input', 'input[name=valores_venda_tags]', function () {
        let value = Number(($(this).val() as string).replace(/\D+/igm, '')).toLocaleString()
        $(this).val('R$ ' + value)
    })
    $('body').on('change', 'select[name=valores_venda_tags]', function () {
        let vals = $(this).tagsinput('items')
        $(this).closest('form').find('[name=valores_venda]').val(vals.join(','))

    })


    $('body').on('input', 'input[name=valores_locacao_tags]', function () {
        let value = Number(($(this).val() as string).replace(/\D+/igm, '')).toLocaleString()
        $(this).val('R$ ' + value)
    })
    $('body').on('change', 'select[name=valores_locacao_tags]', function () {
        let vals = $(this).tagsinput('items')
        $(this).closest('form').find('[name=valores_locacao]').val(vals.join(','))

    })
})


// proprietários

$(function () {
    $('body').on('input', '[data-folder=proprietarios] input[name=emails_tags]', function () {
        /*
        let value = Number(($(this).val() as string).replace(/\D+/igm, '')).toLocaleString()
        $(this).val('R$ ' + value)
        */
        $(this).trigger('change')
    })
    $('body').on('change', '[data-folder=proprietarios] select[name=emails_tags]', function () {
        let vals = $(this).tagsinput('items')
        $(this).closest('form').find('[name=emails]').val(vals.join(','))

    })


    $('body').on('input', '[data-folder=proprietarios] input[name=telefones_tags]', function () {
        //let value = Number(($(this).val() as string).replace(/\D+/igm, '')).toLocaleString()
        //$(this).val('R$ ' + value)
        let value = ($(this).val() as string).replace(/\D+/igm, '').substring(0, 11)
        if (value.length <= 10) {
            value = value.replace(/(\d{0,2})(\d{0,4})(\d{0,5})/igm, '($1) $2-$3').replace(/\D+$/igm, '')
        } else {
            value = value.replace(/(\d{0,2})(\d{0,5})(\d{0,4})/igm, '($1) $2-$3').replace(/\D+$/igm, '')
        }
        $(this).val(value)
    })
    $('body').on('change', '[data-folder=proprietarios] select[name=telefones_tags]', function () {
        let vals = $(this).tagsinput('items')
        $(this).closest('form').find('[name=telefones]').val(vals.join(','))

    })
})