<template>
    <div class="form-group">
        <p>Informe o <b>Webservice Key</b> e clique no botão
            <ArrowRepeatIcon />
            e aguarde os tipos de imóveis do Jetimob.
        </p>

        <label for="configuracoes-codigo" class="form-label">Webservice Key</label>
        <div class="input-group">
            <input type="text" class="form-control" id="configuracoes-codigo" v-model="configuracoes.webservice_key" />
            <div class="input-group-append">
                <button class="btn btn-primary" type="button" @click="atualizarWebserviceKey" title="Atualizar">
                    <ArrowRepeatIcon :class="{spin: atualizando}" />
                </button>
            </div>
        </div>

    </div>
    <div class="row" v-if="Categorias.length !=0 ">
        <div class="col-12 mb-3">
            <h5 class="title">
                Equivalência de Tipos
            </h5>
            <p>Selecione o tipo do Leadlar esquivalente a cada tipo do Jetimob.</p>
        </div>
        <div class="col-12">
            <div class="row mb-3">
                <div class="col-6">Jetimob</div>
                <div class="col-6">Leadlar</div>
            </div>
            <div class="row mb-3" v-for="categoria_config in Categorias">
                <div class="col-6">
                    <input type="text" class="form-control" disabled readonly
                        :value="categoria_config.categoria_jetimob">
                </div>
                <div class="col-6">
                    <select class="form-control" v-model="categoria_config.categoria_id" @change="syncCategorias">
                        <option :value="0">Inativo</option>
                        <option :value="tipo.id" v-for="tipo in tipos">{{ tipo.titulo }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>

import { computed, ref, watch } from 'vue';
import ArrowRepeatIcon from "bootstrap-icons/icons/arrow-repeat.svg?component"
import { Tipo, tipo_repository } from '@/store/tipos';

interface Props {
    modelValue: Record<string, any>
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: Record<string, any>): void
}>()

const configuracoes = ref<Record<string, any>>({ ...props.modelValue } || {})

const Categorias = computed(() => {
    if ('categorias' in configuracoes.value) {
        return configuracoes.value.categorias as CategoriaConfig[]
    }
    return [] as CategoriaConfig[]
})

watch(() => configuracoes.value.webservice_key, () => {
    emit('update:modelValue', { ...configuracoes.value })
})

const syncCategorias = () => {
    emit('update:modelValue', {
        ...configuracoes.value,
        categorias: Categorias.value
    })
}

watch(() => props.modelValue, () => {
    configuracoes.value = { ...props.modelValue }
})

type JetimobResponse = {
    categorias: string[]
}

type CategoriaConfig = {
    categoria_jetimob: string
    categoria_id: number
}

const atualizando = ref<boolean>(false)

const atualizarWebserviceKey = async () => {
    let webservice_key = configuracoes.value.webservice_key
    if (!webservice_key) {
        return
    }    

    atualizando.value = true
    try {
        
        //https://api.jetimob.com/webservice/CXuuDvXM8tA1m9y4tCZKAQ4mCwpEDZhVLbxpyLLI/imoveis/
        let response = await fetch(`jetimob/configuracoes?webservice_key=${webservice_key}`)
        if (!response.ok) {
            console.error(await response.text())
        }
        let jetimob_response = await response.json() as JetimobResponse
    
        let categorias: Map<string, CategoriaConfig> = new Map()
        if ('categorias' in configuracoes.value) {
            for (let row of (configuracoes.value.categorias as CategoriaConfig[])) {
                categorias.set(row.categoria_jetimob, row)
            }
        }    
    
        for (let categoria_jetimob of jetimob_response.categorias) {
            let categoria = categorias.get(categoria_jetimob)
            if (!categoria) {            
                categorias.set(categoria_jetimob, {
                    categoria_jetimob,
                    categoria_id: 0
                })            
            }
        }
    
        configuracoes.value.categorias = Array.from(categorias.values())
    } catch (error) {
        console.error(error)
    }
    atualizando.value = false
}

const tipos = ref<Tipo[]>([])
const updateTipos = async () => {
    try {
        tipos.value = await tipo_repository.getAll()
    } catch (error) {
        console.error(error)
    }
}
updateTipos()
</script>

<style>
/* CSS */
.spin {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>