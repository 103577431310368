<template>
    <form @submit.prevent.stop="doSearch">
        <div class="card">
            <div class="card-body">            
                <div class="d-flex" >                
                    <input type="text" class="form-control " placeholder="O que você está procurando?" v-model="search">
                    <button type="submit" class="btn btn-primary mx-2">
                        <i class="fas fa-search"></i>
                    </button>
                </div>					
            </div>
        </div>

    </form>

    <ExtensoesInstaladas :search="keywords" @editar="editar" @installar="instalar" @remover="remover"></ExtensoesInstaladas>    
    <CategoriasExtensoes :search="keywords" @editar="editar" @installar="instalar" @remover="remover"></CategoriasExtensoes>

    <Modal @submit="save" :titulo="`Configurações: ${extensao.titulo}`" v-if="extensao" :show="show_modal_editar" @close="hideModalEditar" :show_confirm_button="true" confirm_button_text="Salvar">        
        <component :is="extensao.editor" v-model="configuracoes"></component>
    </Modal>
</template>
<script lang="ts" setup>

const search = ref<string>('')
const keywords = ref<string>('')
import Modal from "@/components/editor/Modal.vue";
import ExtensoesInstaladas from "./ExtensoesInstaladas.vue";
import CategoriasExtensoes from "./CategoriasExtensoes.vue";
import { ref } from "vue";
import { account_extensao_repository, account_extensoes, loadExtensoesInstaladas } from "@/store/extensoes";
import { AccountExtensao } from '@/store/extensoes/AccountExtensao';
import { Extensao } from '@/store/extensoes/Extensao';
import Swal from "sweetalert2";

const show_modal_editar = ref<boolean>(false)
const extensao = ref<Extensao | null>(null)
const account_extensao = ref<AccountExtensao | null>(null)
const configuracoes = ref<Record<string, any>>({})

const doSearch = () => {
    keywords.value = search.value
}


const editar = async (record: Extensao) => {
    let row = account_extensoes.value.find(account_extensao => {
        return account_extensao.extensao_id == record.id
    })
    if (!row) {
        return
    }
    account_extensao.value = {...row}
    extensao.value = record
    configuracoes.value = {...row.configuracoes}
    show_modal_editar.value = true
}

const hideModalEditar = () => {
    show_modal_editar.value = false
    account_extensao.value = null
    extensao.value = null
    configuracoes.value = {}
}

const instalar = async (record: Extensao) => {
    try {

 
        Swal.showLoading()
        let result = await account_extensao_repository.save({
            account_id: 0,
            extensao_id: record.id,
            configuracoes: {},
            id: ""
        })
        Swal.fire({
            icon: 'success',
            title: "Extensão instalada"
        })

        await loadExtensoesInstaladas()
        editar(record)
        

    } catch (error) {
        
        let text = 'Não foi possível enviar o formulário'
        if (error instanceof Error) {
            text = error.message
        }
        Swal.hideLoading()
        Swal.fire({
            icon: 'error',
            title: 'Oops',
            text
        })
    } finally {
        loadExtensoesInstaladas()
    }
}

const save = async () => {
    try {
        if (!account_extensao.value) {
            return
        }
        Swal.showLoading()
        await account_extensao_repository.save({
            ...account_extensao.value,
            configuracoes: {
                ...configuracoes.value
            }
        })
        Swal.fire({
            icon: 'success',
            title: "Configuração salva"
        })
        hideModalEditar()

    } catch (error) {
        let text = 'Não foi possível enviar o formulário'
        if (error instanceof Error) {
            text = error.message
        }
        Swal.hideLoading()
        Swal.fire({
            icon: 'error',
            title: 'Oops',
            text
        })
    } finally {
        loadExtensoesInstaladas()
    }
}

const remover = async (record: Extensao) => {
    try {
        let { isConfirmed } = await Swal.fire({
            icon: "warning",
            title: "Deseja desinstalar a extensão?",
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim'
        })
        if (!isConfirmed) {
            return
        }

        Swal.showLoading()
        await account_extensao_repository.remove(record.id)
        Swal.hideLoading()
    } catch (error) {
        let text = 'Não foi possível enviar o formulário'
        if (error instanceof Error) {
            text = error.message
        }
        Swal.hideLoading()
        Swal.fire({
            icon: 'error',
            title: 'Oops',
            text
        })
    } finally {
        loadExtensoesInstaladas()
    }
}

</script>